import Layout from '@/components/layout';
import Seo from '@/components/seo';
import Teaser from '@/components/teaser';
import { GatsbyPageProps, Post } from '@/types';
import { useStaticQuery, graphql } from 'gatsby';
import { values } from 'lodash';
import { ArticleList, Articles } from '../articles';

export default function Home(props: GatsbyPageProps) {
  const { site, allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
          nodes {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
            }
          }
        }
      }
    `,
  );

  const siteTitle = site.siteMetadata?.title || 'Title';
  const posts: Post[] = allMarkdownRemark.nodes;

  return (
    <Layout location={props.location} title={siteTitle}>
      <Seo title="CryptoPlotly | Self-explanatory plotly daily charts for crypto traders: MA, EMA, MACD."></Seo>
      <ol style={{ listStyleType: 'none' }}>
        {values(ArticleList).map((articleId, index) => (
          <li key={index}>
            <Teaser
              date={new Date().toDateString()}
              slug={articleId}
              title={Articles[articleId].title}
              description={Articles[articleId].description}
            />
          </li>
        ))}
      </ol>
      {posts.length > 0 && (
        <section>
          <ol style={{ listStyle: 'none' }}>
            {posts.map((post) => {
              const title = post.frontmatter.title || post.fields.slug;

              return title !== 'test' ? (
                <li key={post.fields.slug}>
                  <Teaser
                    date={post.frontmatter.date}
                    description={post.frontmatter.description || post.excerpt}
                    slug={post.fields.slug}
                    title={title}
                  />
                </li>
              ) : null;
            })}
          </ol>
        </section>
      )}
    </Layout>
  );
}
